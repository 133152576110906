import React from "react";
import {Card, Elevation, Icon} from "@blueprintjs/core";
import "./styles.scss";
import {Link} from "react-router-dom";

function Leads() {
    return (
        <div className="page-wrap dashboard">
            <div className="row mb-15">
                <div className="col-md-4 col-xs-12">
                    <Link to={"/upload"}>
                    <Card className="card" interactive={true} elevation={Elevation.ONE}>
                        <div className="row card-wrap">
                            <div className="col-xs-8">
                                <h2>+</h2>
                                <h5>Bulk Upload Leads</h5>
                            </div>
                            <div className="col-xs-4 icon-wrap">
                                <Icon icon="people" iconSize={72} className="icon"/>
                            </div>
                        </div>
                    </Card>
                    </Link>
                </div>
                <div className="col-md-4 col-xs-12">
                    <Link to={"/reassign"}>
                    <Card className="card" interactive={true} elevation={Elevation.ONE}>
                        <div className="row card-wrap">
                            <div className="col-xs-8">
                                <h2>...</h2>
                                <h5>Re-assign Leads</h5>
                            </div>
                            <div className="col-xs-4 icon-wrap">
                                <Icon icon="updated" iconSize={72} className="icon"/>
                            </div>
                        </div>
                    </Card>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Leads;
