import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface userState {
  id: string
  firstName: string
  lastName: string
  email: string
  accessToken: string
  refreshToken: string
}

interface SliceState {
  user: userState
}

const authentication = createSlice({
  name: 'authentication',
  initialState: {
    user: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      accessToken: '',
      refreshToken: ''
    },
  } as SliceState,
  reducers: {
    setUser: (state, action: PayloadAction<userState>) => {
      state.user = action.payload
    },
  },
})

export const { setUser } = authentication.actions
export default authentication.reducer
