import React from "react";
import { Card, Elevation } from "@blueprintjs/core";
import logo from "../assets/images/logo.png";

const App = () => {
  return (
    <div className="page-wrap home">
      <div className="row p:5@md p:3@xs">
        <div className="col-md-4 col-xs-12 home-hero">
          <div className="">
            <img src={logo} alt="Logo" />
          </div>
        </div>
        <div className="col-md-8 col-xs-12">
          <Card
            interactive={false}
            elevation={Elevation.FOUR}
            className="p:4@md"
          >
            <div className="p:2@md">
              <h3 className="bp3-heading bp3-text-disabled">Welcome to the</h3>
              <h1 className="bp3-heading">Most Connected Platform</h1>
              <h4 className="bp3-heading all-caps bp3-text-muted">
                For your overseas student success
              </h4>
              <button className="bp3-button bp3-intent-success bp3-info-sign">
                Know More
              </button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default App;
