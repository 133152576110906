import React, { Fragment, useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import "./styles/index.scss";
import {
  Tooltip,
  Intent,
  Position,
  Drawer,
  Button,
  Icon,
  Popover,
  Menu,
  MenuItem,
} from "@blueprintjs/core";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppSelector, useAppDispatch } from "../../utility/hooks";
import { useHistory, Link } from "react-router-dom";
import { setUser } from "../../utility/authentication/authenticationSlice";
import useWindowSize from "../../utility/windowSize";
import axios from "axios";

type FormValues = {
  email: string;
  password: string;
};

const Header = () => {
  const history = useHistory();
  const user = useAppSelector((state) => state.authentication.user);
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const drawerSize = () => {
    if (size.width < 650) {
      return "90%";
    } else {
      return "500px";
    }
  };

  useEffect((): any => {
    if (
      window.localStorage.getItem("edConnUserID") &&
      window.localStorage.getItem("edConnAccessToken") &&
      !isLoggedIn
    ) {
      getUser({ data: { data: {} } });
    }
    // eslint-disable-next-line
  }, []);

  const logout = () => {
    dispatch(
      setUser({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        accessToken: "",
        refreshToken: "",
      })
    );
    setIsLoggedIn(false);
    window.localStorage.removeItem("edConnUserID");
    history.push("/");
  };
  const refreshAccess = () => {
    axios
      .post("https://api2.edconnect.in/auth/refresh", {
        refresh_token: window.localStorage.getItem("edConnRefreshToken"),
      })
      .then((res) => {
        window.localStorage.setItem(
          "edConnAccessToken",
          res.data.data.access_token
        );
        getUser({ data: { data: {} } });
        res.data.data.access_token &&
          window.localStorage.setItem(
            "edConnAccessToken",
            res.data.data.access_token
          );
        res.data.data.refresh_token &&
          window.localStorage.setItem(
            "edConnRefreshToken",
            res.data.data.refresh_token
          );
      });
  };
  const getUser = (response) => {
    axios({
      method: "get",
      url: "https://api2.edconnect.in/users/me?fields=id,first_name,last_name,email",
      headers: {
        Authorization: `Bearer ${
          response.data.data.access_token ||
          window.localStorage.getItem("edConnAccessToken")
        }`,
      },
    })
      .then((res) => {
        if (res.data.data) {
          dispatch(
            setUser({
              id: res.data.data.id,
              firstName: res.data.data.first_name,
              lastName: res.data.data.last_name,
              email: res.data.data.email,
              accessToken:
                response.data.data.access_token ||
                window.localStorage.getItem("edConnAccessToken"),
              refreshToken:
                response.data.data.refresh_token ||
                window.localStorage.getItem("edConnRefreshToken"),
            })
          );
          res.data.data.id &&
            window.localStorage.setItem("edConnUserID", res.data.data.id);
          response.data.data.access_token &&
            window.localStorage.setItem(
              "edConnAccessToken",
              response.data.data.access_token
            );
          response.data.data.refresh_token &&
            window.localStorage.setItem(
              "edConnRefreshToken",
              response.data.data.refresh_token
            );
          !isLoggedIn && history.push("/dashboard");
          setIsLoggedIn(true);
        }
      })
      .catch((error) => {
        console.log("Sub", error.response.data);
        if (
          error.response.data.errors[0].extensions.code ===
          "INVALID_CREDENTIALS"
        ) {
          refreshAccess();
        }
      });
  };
  const login: SubmitHandler<FormValues> = (data) => {
    axios
      .post(`https://api2.edconnect.in/auth/login`, {
        email: data.email,
        password: data.password,
      })
      .then((response) => {
        getUser(response);
      })
      .catch((error) => console.log(error));
  };
  return (
    <header className="header">
      <nav className="bp3-navbar bp3-light">
        <div className="bp3-navbar-group bp3-align-left">
          <a
            className="bp3-navbar-heading"
            onClick={() =>
              isLoggedIn ? history.push("/") : history.push("/dashboard")
            }
          >
            <img src={logo} alt="eduConnect Logo" className="logo-ed" />
          </a>
        </div>
        <div className="bp3-navbar-group bp3-align-right">
          {isLoggedIn ? (
            <Fragment>
              <Link
                to={"/leads"}
                className="bp3-button bp3-minimal bp3-icon-people"
              >
                <span className="hidden md:block"> Leads</span>
              </Link>
              <button className="bp3-button bp3-minimal bp3-icon-document">
                <span className="hidden md:block"> Applications</span>
              </button>
              <button className="bp3-button bp3-minimal bp3-icon-locate">
                <span className="hidden md:block"> Tasks</span>
              </button>
              <span className="bp3-navbar-divider" />
              <button className="bp3-button bp3-minimal bp3-icon-notifications" />
              <Popover
                content={
                  <Menu>
                    <MenuItem
                      icon="graph"
                      text="Logout"
                      onClick={() => logout()}
                    />
                  </Menu>
                }
                position={Position.BOTTOM}
              >
                <button className="bp3-button bp3-intent-primary bp3-icon-user">
                  {user.firstName} {user.lastName}
                </button>
              </Popover>
            </Fragment>
          ) : (
            <Fragment>
              <Tooltip
                className="header-tooltip"
                content="For any queries contact dm@edmat.org"
                intent={Intent.PRIMARY}
                position={Position.LEFT}
                usePortal={false}
              >
                <button className="bp3-button bp3-minimal bp3-icon-help" />
              </Tooltip>
              <button
                className="bp3-button bp3-intent-primary bp3-icon-user"
                onClick={() => setIsLoginOpen(true)}
              >
                Login
              </button>
              <Drawer
                className="header-login"
                icon="info-sign"
                onClose={() => setIsLoginOpen(false)}
                title="Login"
                isOpen={isLoginOpen}
                size={drawerSize()}
              >
                <div className="p:4@md p:4@xs">
                  <form onSubmit={handleSubmit(login)}>
                    <div className="home-form">
                      <div className="bp3-form-group .modifier">
                        <label className="bp3-label" htmlFor="form-group-input">
                          Email
                          <span className="bp3-text-muted">*</span>
                        </label>
                        <div className="bp3-form-content">
                          <div className="bp3-input-group .modifier">
                            <input
                              id="email"
                              className="bp3-input"
                              placeholder="user@edmat.org"
                              type="email"
                              {...register("email", { required: true })}
                              required
                            />
                            {errors.email && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bp3-form-group .modifier">
                        <label className="bp3-label" htmlFor="form-group-input">
                          Password
                          <span className="bp3-text-muted">*</span>
                        </label>
                        <div className="bp3-form-content">
                          <div className="bp3-input-group .modifier">
                            <input
                              id="password"
                              className="bp3-input"
                              placeholder="Password"
                              type="password"
                              {...register("password", { required: true })}
                              required
                            />
                            {errors.password && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Button type="submit" intent="primary" className="pr:3@xs">
                      Login &ensp; <Icon icon="arrow-right" />
                    </Button>
                  </form>
                </div>
              </Drawer>
            </Fragment>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
