import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

export const isLoggedIn = () => {
  if (window.localStorage.getItem("edConnUserID") && window.localStorage.getItem("edConnAccessToken")) {
    return true;
  }
  return false;
};

interface PrivateRouteProps extends RouteProps {
  component: any;
  path: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component, path, ...rest } = props;

  return isLoggedIn() ? (
    <Route {...rest} path={props.path} component={props.component} />
  ) : (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
};

export default PrivateRoute;
