import React, { useState, useEffect } from "react";
import { Button, Card, Elevation, NonIdealState } from "@blueprintjs/core";
import Select from "react-select";
import MaterialTable from "material-table";
import "react-step-progress-bar/styles.css";
import "../styles.scss";
import axios from "axios";
import moment from "moment";
import { DateInput, IDateFormatProps } from "@blueprintjs/datetime";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const ReassignLead = () => {
  const [loading, setLoading] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [leads, setLeads] = useState([]);
  const [locations, setLocations] = useState([]);
  const [opportunity, setOpportunity] = useState([]);
  const [intakeyear, setIntakeyear] = useState([]);
  const [intakemonth, setIntakemonth] = useState([]);
  const [source, setSource] = useState([]);
  const [sousub, setSousub] = useState([]);
  const [soudes, setSoudes] = useState([]);
  const [counsellor, setCounsellor] = useState([]);

  const [Selectedlocations, setSelectedLocations] = useState();
  const [Selectedopportunity, setSelectedOpportunity] = useState();
  const [Selectedintakeyear, setSelectedIntakeyear] = useState();
  const [Selectedintakemonth, setSelectedIntakemonth] = useState();
  const [Selectedsource, setSelectedSource] = useState();
  const [Selectedsousub, setSelectedSousub] = useState();
  const [Selectedsoudes, setSelectedSoudes] = useState();
  const [Selectedcounsellor, setSelectedCounsellor] = useState();
  const [SelectedRecounsellor, setSelectedReCounsellor] = useState();
  const [SelectedDate, setSelectedDate] = useState();
  const [SelectedLeads, setSelectedLeads] = useState();

  function getMomentFormatter(format: string): IDateFormatProps {
    // note that locale argument comes from locale prop and may be undefined
    return {
      formatDate: (date, locale) => moment(date).locale(locale).format(format),
      parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
      placeholder: format,
    };
  }

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = () => {
    const basedURL = "https://api2.edconnect.in/items/";
    const baseLegacyURL = "https://api.edconnect.in/v1/";
    setLoading(true);
    axios({
      method: "get",
      url: basedURL + "location",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem(
          "edConnAccessToken"
        )}`,
      },
    })
      .then((response) => {
        const location = response.data.data.map((loc) => {
          return { label: loc.emloc, value: loc.emloc };
        });
        setLocations(location);
        axios({
          method: "get",
          url: baseLegacyURL + "opportunities",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "edConnAccessToken"
            )}`,
          },
        })
          .then((response) => {
            const opps = response.data.map((item) => {
              return { label: item, value: item };
            });
            setOpportunity(opps);
            axios({
              method: "get",
              url: baseLegacyURL + "intakeyear",
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "edConnAccessToken"
                )}`,
              },
            })
              .then((response) => {
                const opps = response.data.map((item) => {
                  return { label: item, value: item };
                });
                setIntakeyear(opps);
                axios({
                  method: "get",
                  url: baseLegacyURL + "intakemonth",
                  headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                      "edConnAccessToken"
                    )}`,
                  },
                })
                  .then((response) => {
                    const opps = response.data.map((item) => {
                      return { label: item, value: item };
                    });
                    setIntakemonth(opps);
                    axios({
                      method: "get",
                      url: baseLegacyURL + "source",
                      headers: {
                        Authorization: `Bearer ${window.localStorage.getItem(
                          "edConnAccessToken"
                        )}`,
                      },
                    })
                      .then((response) => {
                        const opps = response.data.map((item) => {
                          return { label: item, value: item };
                        });
                        setSource(opps);
                        axios({
                          method: "get",
                          url: baseLegacyURL + "sousub",
                          headers: {
                            Authorization: `Bearer ${window.localStorage.getItem(
                              "edConnAccessToken"
                            )}`,
                          },
                        })
                          .then((response) => {
                            const opps = response.data.map((item) => {
                              return { label: item, value: item };
                            });
                            setSousub(opps);
                            axios({
                              method: "get",
                              url: baseLegacyURL + "soudes",
                              headers: {
                                Authorization: `Bearer ${window.localStorage.getItem(
                                  "edConnAccessToken"
                                )}`,
                              },
                            })
                              .then((response) => {
                                const opps = response.data.map((item) => {
                                  return { label: item, value: item };
                                });
                                setSoudes(opps);
                                axios({
                                  method: "get",
                                  url: baseLegacyURL + "counsellors",
                                  headers: {
                                    Authorization: `Bearer ${window.localStorage.getItem(
                                      "edConnAccessToken"
                                    )}`,
                                  },
                                })
                                  .then((response) => {
                                    const opps = response.data.map((item) => {
                                      return { label: item, value: item };
                                    });
                                    setCounsellor(opps);
                                    setLoading(false);
                                  })
                                  .catch((err) => console.log(err));
                              })
                              .catch((err) => console.log(err));
                          })
                          .catch((err) => console.log(err));
                      })
                      .catch((err) => console.log(err));
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "First Name",
      field: "firstName",
    },
    {
      title: "Last Name",
      field: "lastName",
    },
    {
      title: "Phone",
      field: "phone",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Source",
      field: "source",
    },
    {
      title: "Category",
      field: "sousub",
    },
    {
      title: "Description",
      field: "soudes",
    },
    {
      title: "Opportunity",
      field: "opportunity",
    },
    {
      title: "Lead Group",
      field: "lgroup",
    },
    {
      title: "Location",
      field: "emloc",
    },
  ];

  const applyFilter = () => {
    const basedURL = "https://api2.edconnect.in/items/";
    setLoading(true);
    axios({
      method: "get",
      url:
        basedURL +
        `leadprofile?filter[emloc][_eq]=${Selectedlocations.value}${
          Selectedcounsellor && Selectedcounsellor.value
            ? "&filter[counsellor][_eq]=" + Selectedcounsellor.value
            : ""
        }${
          Selectedintakeyear && Selectedintakeyear.value
            ? "&filter[intakeyear][_eq]=" + Selectedintakeyear.value
            : ""
        }${
          Selectedintakemonth && Selectedintakemonth.value
            ? "&filter[intakemonth][_eq]=" + Selectedintakemonth.value
            : ""
        }${
          Selectedopportunity && Selectedopportunity.value
            ? "&filter[opportunity][_eq]=" + Selectedopportunity.value
            : ""
        }${
          Selectedsource && Selectedsource.value
            ? "&filter[source][_eq]=" + Selectedsource.value
            : ""
        }${
          Selectedsousub && Selectedsousub.value
            ? "&filter[sousub][_eq]=" + Selectedsousub.value
            : ""
        }${
          Selectedsoudes && Selectedsoudes.value
            ? "&filter[soudes][_eq]=" + Selectedsoudes.value
            : ""
        }&limit=-1`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem(
          "edConnAccessToken"
        )}`,
      },
    })
      .then((response) => {
        if (response.data.data && response.data.data.length) {
          setLeads(response.data.data);
          setFilterApplied(true);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const addNewTaks = () => {
    const basedURL = "https://api2.edconnect.in/items/";
    const mappedData = SelectedLeads.map((lead) => {
      return {
        leadid: lead.id,
        tasktype: "Call",
        schedule: moment(SelectedDate).format("YYYY-MM-DD"),
        scheduletime: "09:00",
      };
    });

    axios({
      method: "post",
      url: basedURL + `tasks`,
      data: mappedData,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem(
          "edConnAccessToken"
        )}`,
      },
    })
      .then((response) => {
        setFilterApplied(false);
        setLeads();
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const reassignLeads = () => {
    const ids = SelectedLeads.map((lead) => lead.id);
    const basedURL = "https://api2.edconnect.in/items/";
    setLoading(true);
    axios({
      method: "patch",
      url: basedURL + `leadprofile`,
      data: {
        keys: ids,
        data: {
          counsellor: SelectedRecounsellor.value,
        },
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem(
          "edConnAccessToken"
        )}`,
      },
    })
      .then((response) => {
        if (response.data.data && response.data.data.length) {
          addNewTaks();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="page-wrap leads">
      <div className="row mb-15">
        <div className="col-md-8 col-md-offset-2 col-xs-12">
          <h2 className="pt-12 pb-6 badd-title">Re-assign Leads</h2>
          <Card className="card p-6 mb-6" elevation={Elevation.FOUR}>
            <h3 className="pb-2 badd-title">Filters</h3>
            <div className="row">
              <div className="col-md-3 col-xs-12">
                <label className="pb-2">Location</label>
                <Select
                  className="pt-1"
                  options={locations}
                  onChange={(e) => setSelectedLocations(e)}
                />
              </div>
              <div className="col-md-3 col-xs-12">
                <label className="pb-2">Opportunity</label>
                <Select
                  className="pt-1"
                  options={opportunity}
                  onChange={(e) => setSelectedOpportunity(e)}
                />
              </div>
              <div className="col-md-3 col-xs-12">
                <label className="pb-2">Intake Year</label>
                <Select
                  className="pt-1"
                  options={intakeyear}
                  onChange={(e) => setSelectedIntakeyear(e)}
                />
              </div>
              <div className="col-md-3 col-xs-12">
                <label className="pb-2">Intake Month</label>
                <Select
                  className="pt-1"
                  options={intakemonth}
                  onChange={(e) => setSelectedIntakemonth(e)}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3 col-xs-12">
                <label className="pb-2">Source</label>
                <Select
                  className="pt-1"
                  options={source}
                  onChange={(e) => setSelectedSource(e)}
                />
              </div>
              <div className="col-md-3 col-xs-12">
                <label className="pb-2">Category</label>
                <Select
                  className="pt-1"
                  options={sousub}
                  onChange={(e) => setSelectedSousub(e)}
                />
              </div>
              <div className="col-md-3 col-xs-12">
                <label className="pb-2">Description</label>
                <Select
                  className="pt-1"
                  options={soudes}
                  onChange={(e) => setSelectedSoudes(e)}
                />
              </div>
              <div className="col-md-3 col-xs-12">
                <label className="pb-2">Counsellor</label>
                <Select
                  className="pt-1"
                  options={counsellor}
                  onChange={(e) => setSelectedCounsellor(e)}
                />
              </div>
            </div>
            <Button
              rightIcon="tick"
              className="mt-6 bp3-intent-primary"
              loading={loading}
              disabled={!Selectedlocations}
              onClick={() => applyFilter()}
            >
              Apply Filter
            </Button>
          </Card>

          <Card className="card p-6 mb-6" elevation={Elevation.FOUR}>
            {filterApplied ? (
              leads.length > 0 ? (
                <div className="table-wrap">
                  <MaterialTable
                    title="Leads matching the filter"
                    columns={columns}
                    data={leads}
                    icons={tableIcons}
                    onSelectionChange={(e) => setSelectedLeads(e)}
                    options={{
                      selection: true,
                      pageSize: 50,
                      pageSizeOptions: [50, 100, 500],
                    }}
                  />
                </div>
              ) : (
                <NonIdealState
                  icon="graph-remove"
                  title="No matching leads"
                  description="Please re-try applying filters with relevant options"
                />
              )
            ) : (
              <NonIdealState
                icon="exclude-row"
                title="No filers applied"
                description="Please apply filters with relevant options to see leads"
              />
            )}
          </Card>

          <Card className="card p-6" elevation={Elevation.FOUR}>
            <div className="row mt-4">
              <div className="col-md col-xs-12">
                <label className="pb-2">
                  Select the Counsellor to re-assign the leads to{" "}
                </label>
                <Select
                  className="pt-1"
                  options={counsellor}
                  onChange={(e) => setSelectedReCounsellor(e)}
                />
              </div>
              <div className="col-md-4 col-xs-12">
                <label className="pb-2">
                  Select the date to schedule calls for these leads{" "}
                </label>
                <div className="pt-1 e-date-picker">
                  <DateInput
                    fill
                    minDate={new Date()}
                    onChange={(e) => {
                      setSelectedDate(e);
                      console.log(e);
                    }}
                    {...getMomentFormatter("YYYY-MM-DD")}
                    locale="in"
                  />
                </div>
              </div>
              <Button
                rightIcon="exchange"
                className="mt-6 bp3-intent-success"
                loading={loading}
                disabled={!SelectedRecounsellor || !SelectedDate}
                onClick={() => reassignLeads()}
              >
                Re-assign Leads
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ReassignLead;
