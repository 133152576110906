import React, {useEffect, useState} from "react";
import {Card, Elevation, Icon} from "@blueprintjs/core";
import "./styles.scss";
import axios from "axios";

function Dashboard() {
    const [leadCount, setLeadCount] = useState();
    const [loading, setLoading] = useState(true);

    useEffect((): any => {
        getCount();
        // eslint-disable-next-line
    }, [])

    const getCount = () => {
        axios({
            method: 'get',
            url: 'https://api2.edconnect.in/items/leadprofile?fields=id&meta=total_count',
            headers: {"Authorization": `Bearer ${window.localStorage.getItem("edConnAccessToken")}`}
        })
            .then(res => {
                setLeadCount(res.data.meta.total_count);
                setLoading(false);
            }).catch(error => {
            console.log(error);
            setLoading(false)
        })
    }
    return (
        <div className="page-wrap dashboard">
            <div className="row mb-15">
                <div className="col-md-4 col-xs-12">
                    <Card className="card" interactive={true} elevation={Elevation.ONE}>
                        <div className="row card-wrap">
                            <div className="col-xs-8">
                                <h2>{!loading && leadCount}</h2>
                                <h5>Total Leads</h5>
                            </div>
                            <div className="col-xs-4 icon-wrap">
                                <Icon icon="people" iconSize={72} className="icon"/>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-md-4 col-xs-12">
                    <Card className="card" interactive={true} elevation={Elevation.ONE}>
                        <div className="row card-wrap">
                            <div className="col-xs-8">
                                <h2>0</h2>
                                <h5>Your Active Applications</h5>
                            </div>
                            <div className="col-xs-4 icon-wrap">
                                <Icon icon="updated" iconSize={72} className="icon"/>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-md-4 col-xs-12">
                    <Card className="card" interactive={true} elevation={Elevation.ONE}>
                        <div className="row card-wrap">
                            <div className="col-xs-8">
                                <h2>0</h2>
                                <h5>Your Open Tasks</h5>
                            </div>
                            <div className="col-xs-4 icon-wrap">
                                <Icon icon="outdated" iconSize={72} className="icon"/>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
