import React from "react";
import ReactDOM from "react-dom";
import Header from "./app/layout/header";
import * as serviceWorker from "./serviceWorker";
import "./assets/css/index.scss";
import PrivateRoute from "./utility/authentication/privateRoute";
import App from "./app";
import NotFound from "./app/pages/NotFound";
import Dashboard from "./app/pages/Dashboard";
import Leads from "./app/pages/Leads";
import BulkLeadUpload from "./app/pages/Leads/Upload";
import ReassignLead from "./app/pages/Leads/Reassign";
import { createBrowserHistory } from "history";

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";

// redux toolkit
import { Provider } from "react-redux";
import store from "./store";

export const appHistory = createBrowserHistory();

const routing = (
  <Router history={appHistory}>
    <Provider store={store}>
      <Header />
      <div className="page">
        <Switch>
          <Route exact path="/" component={App} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/leads" component={Leads} />
          <PrivateRoute path="/upload" component={BulkLeadUpload} />
          <PrivateRoute path="/reassign" component={ReassignLead} />
          <Route path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </div>
    </Provider>
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));

serviceWorker.unregister();
