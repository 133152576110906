import React, {useState, useMemo} from "react";
import {useTable} from "react-table";
import {Card, Elevation, Button, Callout} from "@blueprintjs/core";
import axios from "axios";
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";
import {CSVReader} from 'react-papaparse'
import "../styles.scss";

const BulkLeadUpload = () => {
    const [fileContent, setFileContent] = useState<any>([]);
    const [step, setStep] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [uploaded, setUploaded] = useState<boolean>(false);
    const handleOnDrop = (data) => {
        const newData = data.map(lead => lead.data);
        setFileContent(newData);
    }
    const handleOnError = () => {
        setFileContent(null)
    }
    const handleOnRemoveFile = (data) => {
        setFileContent(data)
    }
    const onSubmit = () => {
        const newStep = step + 1;
        setStep(newStep);
    }
    const goBack = () => {
        const newStep = step - 1;
        setStep(newStep);
    }


    const doNext = () => {
        setLoading(false);
        setUploaded(true);
    }

    
    const onUpload = async() => {
        setLoading(true);
        await Promise.all(fileContent.map(lead => {
            const checkLeadURL = `https://api2.edconnect.in/items/leadprofile?filter[phone][_eq]=${lead.phone}`;
            const uploadLeadURL = 'https://api2.edconnect.in/items/leadprofile';
            axios({
                method: 'get',
                url: checkLeadURL,
                headers: {"Authorization": `Bearer ${window.localStorage.getItem("edConnAccessToken")}`}
            }).then(res => {
                if(res.data.data.length > 0){
                    axios({
                        method: 'patch',
                        url: uploadLeadURL+`/${res.data.data[0].id}`,
                        data: lead,
                        headers: {"Authorization": `Bearer ${window.localStorage.getItem("edConnAccessToken")}`}
                    }).then(response => {
                        console.log(response.data);
                    }).catch(err => console.log(err))
                }else{
                    axios({
                        method: 'post',
                        url: uploadLeadURL,
                        data: lead,
                        headers: {"Authorization": `Bearer ${window.localStorage.getItem("edConnAccessToken")}`}
                    }).then(response => {
                        const datum = JSON.stringify({
                            attributes: {
                              FIRSTNAME: lead.firstName,
                              LASTNAME: lead.lastName,
                              SMS: "91" + lead.phone,
                              OPPORTUNITY: "Unassigned",
                              CITY: lead.emloc,
                              YOP: lead.yop,
                              QUALIFICATION: lead.edqualify,
                              INTAKE: lead.intake
                            },
                            listIds: [76, 87],
                            updateEnabled: true,
                            email: lead.email,
                          });

                          axios.post("https://api.interakt.ai/v1/public/track/users/", {
                            
                                "phoneNumber": lead.phone,
                                "countryCode": "+91",
                                "traits": {
                                  "name": lead.firstName+" "+lead.lastName,
                                  "email": lead.email,
                                  "emloc": lead.emloc,
                                  "yop": lead.yop,
                                  "opportunity": "unassigned"
                                }
                              },
                                {headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                  "Authorization":
                                    "Basic bUdrcjlPWXlRU0V1Y2JVX3ZZdTczYUdyM0RYcnRaZDNXNllfb2ZabnVtUTo=",
                                }
                                }
                          ).then(res => {
                            const date = new Date();
                            axios.post("https://api.interakt.ai/v1/public/track/events/", {
                                
                                    "phoneNumber": lead.phone,
                                    "countryCode": "+91",
                                    "event": "LeadCreated",
                                    "traits": {
                                      "createdDate": date
                                    }
                                  },
                                    {headers: {
                                      Accept: "application/json",
                                      "Content-Type": "application/json",
                                      "Authorization":
                                        "Basic bUdrcjlPWXlRU0V1Y2JVX3ZZdTczYUdyM0RYcnRaZDNXNllfb2ZabnVtUTo=",
                                    }
                                }
                              )
                          });
                          fetch("https://api.sendinblue.com/v3/contacts", {
                            method: "POST",
                            headers: {
                              Accept: "application/json",
                              "Content-Type": "application/json",
                              "api-key":
                                "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",
                            },
                            body: datum,
                          });
                        console.log(response.data);
                    }).catch(err => console.log(err))
                }
            })
            return null;
        }));
        setTimeout(doNext() , 5000)
    }


    const data = useMemo(
        () => fileContent,
        [fileContent]
    )

    const columns = useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Education Qualification',
                accessor: 'edqualify',
            },
            {
                Header: 'YOP',
                accessor: 'yop',
            },
            {
                Header: 'Source',
                accessor: 'source',
            },
            {
                Header: 'Category',
                accessor: 'sousub',
            },
            {
                Header: 'Description',
                accessor: 'soudes',
            },
            {
                Header: 'Date',
                accessor: 'soudate',
            },
            {
                Header: 'Department',
                accessor: 'dept',
            },
            {
                Header: 'Institution',
                accessor: 'linst',
            },
            {
                Header: 'Opportunity',
                accessor: 'opportunity',
            },
            {
                Header: 'Lead Group',
                accessor: 'lgroup',
            },
            {
                Header: 'Location',
                accessor: 'emloc',
            },
            {
                Header: 'gCell Member',
                accessor: 'gm',
            },
            {
                Header: 'gCell CA',
                accessor: 'gca',
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns, data})


    return (
        <div className="page-wrap leads">
            <div className="row mb-15">
                <div className="col-md-8 col-md-offset-2 col-xs-12">
                    <h2 className="pt-12 pb-6 badd-title">Bulk Upload Leads</h2>
                    <Card className="card p-12" elevation={Elevation.FOUR}>
                        <div className="mb-16 mt-8 px-6 pb-6">
                            <ProgressBar percent={step === 1 ? 0 : step === 2 ? 50 : 100}>
                                <Step>
                                    {({accomplished, index}) => (
                                        <div className="flex flex-col pt-6 text-center step-title--wrap">
                                            <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                                                {index + 1}
                                            </div>
                                            <h3 className="pt-2">Add</h3>
                                        </div>
                                    )}
                                </Step>
                                <Step>
                                    {({accomplished, index}) => (
                                        <div className="flex flex-col pt-6 text-center step-title--wrap">
                                            <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                                                {index + 1}
                                            </div>
                                            <h3 className="pt-2">Confirm</h3>
                                        </div>
                                    )}
                                </Step>
                                <Step>
                                    {({accomplished, index}) => (
                                        <div className="flex flex-col pt-6 text-center step-title--wrap">
                                            <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                                                {index + 1}
                                            </div>
                                            <h3 className="pt-2">Upload</h3>
                                        </div>
                                    )}
                                </Step>
                            </ProgressBar>
                        </div>
                        {step === 1 &&
                        <div className="">
                            <CSVReader
                                onDrop={handleOnDrop}
                                onError={handleOnError}
                                addRemoveButton
                                config={{header: true}}
                                onRemoveFile={handleOnRemoveFile}
                            >
                                <span>Drop CSV file here or click to upload.</span>
                            </CSVReader>
                        </div>
                        }
                        {step === 2 &&
                        <div className="table-wrap">
                            <table {...getTableProps()} className="table">
                                <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps()}
                                                className="text-left"
                                            >
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        }
                        {step === 3 &&
                        <div>
                            {!uploaded ? "Are you sure to upload these leads?" :
                            <Callout icon="tick" intent="success">Successfully started the upload of leads. Please donot refresh this page for 2-4 mins</Callout>}
                        </div>
                        }
                        <div className="step-footer--wrap flex">
                            <Button icon="arrow-left" className="mt-6 mr-2" disabled={step === 1}
                                    onClick={() => goBack()}>Back</Button>
                            {step === 1 ?
                                <Button rightIcon="arrow-right" className="mt-6 bp3-intent-primary"
                                        disabled={!fileContent && fileContent !== ''}
                                        onClick={() => onSubmit()}>Continue</Button> : step === 2 ?
                                    <Button rightIcon="arrow-right" className="mt-6 bp3-intent-primary"
                                            onClick={() => onSubmit()}>Continue</Button> :
                                    <Button rightIcon="upload" className="mt-6 bp3-intent-success"
                                            disabled={!fileContent} loading={loading} onClick={() => onUpload()}>Upload</Button>
                            }
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default BulkLeadUpload;
