import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "../utility/authentication/authenticationSlice";

const store = configureStore({
  reducer: combineReducers({
    authentication: authReducer,
  }),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
